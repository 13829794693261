<template>
    <section class="content">
        <header class="content__title">
            <h1>Subscriptions</h1>
            <small></small>
            <div class="actions"></div>
        </header>
    
        <div class="card">
            <!-- <pre>{{ payments }}</pre> -->
            <Processing v-if="isLoading" />
            <table class="table" v-else-if="payments.length > 0">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <th>TxnID</th>
                        <th>Amount</th>
                        <th>Devotional</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(donation, i) in payments" :key="i">
                        <td>{{ donation.created }}</td>
                        <td>{{ donation.name }}</td>
                        <td>{{ donation.txref }}</td>
                        <td>{{ donation.currency + ' ' + donation.amount }}</td>
                        <td>{{ donation.devotional }}</td>
                        <td>{{ donation.status }}</td>
                    </tr>
                </tbody>
            </table>
            <div v-else class="notfound">
                <img src="img/notfound.svg" alt="">
            </div>

        </div>
    </section>

</template>

<script>
import moment from "moment"
export default {
    data(){
        return {
            payments: [],
            isLoading: false,
        }
    },
    created(){
        this.load()
    },
    methods: {
        async load(){
            this.isLoading = true
            try {
                const snap = await this.$db.collection("subscriptions").orderBy("createdAt", "desc").get()
                snap.forEach(doc => {
                    const data = doc.data()
                    data.created = moment(data.createdAt.toDate()).format("DD/MM/YYYY HH:mm:ss a")
                    this.payments.push({ id: doc.id, ...data })
                })
                this.isLoading = false
            } catch (error) {
                this.isLoading = false
            }
        },
        view(id){
            this.$router.push("/payments/d/" + id)
        },
    },
    components: {
        "Processing": () => import("@/components/Loaders/Processing.vue")
    }
}
</script>


<style scoped>
.listview__img_payments {
    width: 40.50px;
    height: 40.50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 1.2rem;
}
.listview__heading {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
</style>